import React, { FC } from 'react';

// dummyなので消す
// eslint-disable-next-line import/extensions
import itemUrl from '@assets/image/svg/dummy/item1.svg';
import { Button } from '@components/controls/button';
import { Block } from '@components/layout/block';
import { Flex } from '@components/layout/flexbox';
import { Heading2 } from '@components/typographies/heading';
import { Paragraph } from '@components/typographies/paragraph';

type Props = {
  title: string;
};
export const Favorites: FC<Props> = ({ title }) => (
  <Block width="100%" padding="30px 30px">
    <Heading2 fontSize="24px" lh="40px">
      {title}
    </Heading2>
    <Flex flexWrap="wrap" jc="flex-start">
      <Block width="130px" margin="10px 8px">
        <img src={itemUrl} />
        <Paragraph lh="30px" color="gray">
          台湾カルディ
        </Paragraph>
        <Paragraph>バレンタイン限定 ラズベリーとローズのマカロン 詰め合わせ</Paragraph>
        <Paragraph lh="30px" fontWeight="700">
          ¥3,600
        </Paragraph>
        <Button radius="5px" bg="white" color="orange" border="1px solid #F39800">
          カートに入れる
        </Button>
      </Block>
    </Flex>
  </Block>
);
